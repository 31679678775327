// var total = 76, page = 1, pageTotal = 10;
// initPage(total, page, pageTotal, toPage, {code: page});
/**
 * 分页
 * @param total 记录总条数
 * @param page 当前页码
 * @param pageTotal 总页数
 * @param toPage 跳转页码函数第一个参数为page
 * @param ele 父元素
 * @param params 除当前页码以外的参数
 * @param others1 除当前页码以外的参数
 * @param others2 除当前页码以外的参数
 * @param others3 除当前页码以外的参数
 * @param others4 除当前页码以外的参数
 */
function initPage(total, page, pageTotal, toPage,ele, params,others1,others2,others3,others4) {
    if(total==0){
        if(ele){
            ele.find(".P_page").html("").css("display","none");
        }else {
            $(".P_page").html("").css("display","none");
        }
        return;
    }
    var html = '<div class="P_toPage"> ' +
        '<input type="text"/><div>GO</div> ' +
        '</div><div class="P_last">末页</div><div class="P_next">下一页</div><div class="P_pageNum"></div> <div class="P_previous">' +
        '上一页</div><div class="P_pageText">' +
        '共<span>' + total + '</span>条记录 当前为<span>' + page + '</span>页 共<span>' + pageTotal + '</span>页</div>'
    if(ele){
        ele.find(".P_page").html(html).css("display","block");
        ele.find(".P_toPage input").on("keyup",function(){
            $(this).val($(this).val().replace(/\D/gi,""))
        });
    }else {
        $(".P_page").html(html).css("display","block");
        $(".P_toPage input").on("keyup",function(){
            $(this).val($(this).val().replace(/\D/gi,""))
        });
    }
    if (pageTotal <= 5) {
        var html1 = '';
        for (var i = 0; i < pageTotal; i++) {
            var c = "";
            if (i + 1 == page) {
                c = "P_active";
            }
            var p = i + 1;
            html1 += '<div class="' + c + '">' + p + '</div>'
        }
        if(ele){
            ele.find(".P_pageNum").html(html1);
        }else {
            $(".P_pageNum").html(html1);
        }
    }else {
        if(page<4){
            var html1 = '';
            for (var i = 0; i < 5; i++) {
                var c = "";
                if (i + 1 == page) {
                    c = "P_active";
                }
                var p = i + 1;
                html1 += '<div class="' + c + '">' + p + '</div>'
            }
            if(ele){
                ele.find(".P_pageNum").html(html1);
            }else {
                $(".P_pageNum").html(html1);
            }
        }else if(page<=pageTotal-2){
            var html1 = '<div>1</div><div>...</div>';
            for (var i = page; i <page+3 ; i++) {
                var c = "";
                if (i == page) {
                    c = "P_active";
                }
                html1 += '<div class="' + c + '">' + i + '</div>'
            }
            if(ele){
                ele.find(".P_pageNum").html(html1);
            }else {
                $(".P_pageNum").html(html1);
            }
        }else if(page==pageTotal-1){
            var html1 = '<div>1</div><div>...</div><div>'+(page-1)+'</div><div class="P_active">'+page+'</div><div>'+pageTotal+'</div>';
            if(ele){
                ele.find(".P_pageNum").html(html1);
            }else {
                $(".P_pageNum").html(html1);
            }
        }else {
            var html1 = '<div>1</div><div>...</div><div>'+(page-2)+'</div><div>'+(page-1)+'</div><div class="P_active">'+page+'</div>';
            if(ele){
                ele.find(".P_pageNum").html(html1);
            }else {
                $(".P_pageNum").html(html1);
            }
        }
    }
    var time = 200;
    //点击页码
    if(ele){
        ele.find(".P_pageNum div").click(function(){
            var p = parseInt($(this).html());
            if(!isNaN(p)){
                if(p!=page){
                    setTimeout(function(){
                        toPage(p, params,others1,others2,others3,others4);
                    },time);
                }
            }
        });
        //点击末页
        ele.find(".P_last").click(function () {
            if (page != pageTotal) {
                setTimeout(function(){
                    toPage(pageTotal, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击下一页
        ele.find(".P_next").click(function () {
            if (page < pageTotal) {
                setTimeout(function(){
                    toPage(page + 1, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击上一页
        ele.find(".P_previous").click(function () {
            if (page > 1) {
                setTimeout(function(){
                    toPage(page - 1, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击跳转
        ele.find(".P_toPage div").click(function () {
            var p = parseInt(ele.find(".P_toPage input").val());
            if (p <= pageTotal && p != page) {
                setTimeout(function(){
                    toPage(p, params,others1,others2,others3,others4);
                },time);
            }
        });
    }else {
        $(".P_pageNum div").click(function(){
            var p = parseInt($(this).html());
            if(!isNaN(p)){
                if(p!=page){
                    setTimeout(function(){
                        toPage(p, params,others1,others2,others3,others4);
                    },time);
                }
            }
        });
        //点击末页
        $(".P_last").click(function () {
            if (page != pageTotal) {
                setTimeout(function(){
                    toPage(pageTotal, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击下一页
        $(".P_next").click(function () {
            if (page < pageTotal) {
                setTimeout(function(){
                    toPage(page + 1, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击上一页
        $(".P_previous").click(function () {
            if (page > 1) {
                setTimeout(function(){
                    toPage(page - 1, params,others1,others2,others3,others4);
                },time);
            }
        });
        //点击跳转
        $(".P_toPage div").click(function () {
            var p = parseInt($(".P_toPage input").val());
            if (p <= pageTotal && p != page) {
                setTimeout(function(){
                    toPage(p, params,others1,others2,others3,others4);
                },time);
            }
        });
    }
}
