/**
 * 只能输数字
 * @param obj
 */
function onlyNum(obj) {
    obj.value = obj.value.replace(/\D/gi, ""); //清除"数字"和"."以外的字符
}
/**
 * 控制时间
 * 
 */
function endTimeControl(start,end,total){
    var startTime="";
    var endTime="";
    end.datepicker({
        language: 'zh-CN',
        format: 'yyyy-mm-dd',
        orientation:'bottom' ,
        startView: 0,  
        autoclose: true,  
        startDate:start.val()?new Date(start.val()):new Date(),   
    }).on("changeDate",function(ev){
        endTime=ev.date.valueOf();
        if(startTime!==""){
            var totalTime=(endTime-startTime)/1000/60/60/24;
            total.val(totalTime+1);
        }
        if(start.val()!==""){
            startTime=stringToDate(start.val()).valueOf()
            var totalTime=(endTime-startTime)/1000/60/60/24;
            total.val(totalTime+1);
        }
        if(ev.date){  
            $(start).datepicker('setEndDate', new Date(ev.date.valueOf()-24*60*60*1000))  
        }else{  
            $(start).datepicker('setEndDate',null);  
        }  
    })
    start.datepicker({
        language: 'zh-CN',
        format: 'yyyy-mm-dd',
        orientation:'bottom' ,
        startView: 0, 
        autoclose: true,  
        startDate:new Date(),
        endDate:end.val()?new Date(end.val()):"",
    }).on("changeDate",function(ev){
        startTime=ev.date.valueOf();
        if(endTime!==""){
            var totalTime=(endTime-startTime)/1000/60/60/24;
            total.val(totalTime+1)
        }
        if(end.val()!==""){
            endTime=stringToDate(end.val()).valueOf()
            var totalTime=(endTime-startTime)/1000/60/60/24;
            total.val(totalTime+1);
        }
        if(ev.date){  
            $(end).datepicker('setStartDate', new Date(ev.date.valueOf()+24*60*60*1000)) 
            
        }else{  
            $(end).datepicker('setStartDate',new Date());  
        }  
    })
}
 function stringToDate(dateStr,separator){
    if(!separator){
           separator="-";
    }
    var dateArr = dateStr.split(separator);
    var year = parseInt(dateArr[0]);
    var month;
    //处理月份为04这样的情况                         
    if(dateArr[1].indexOf("0") == 0){
        month = parseInt(dateArr[1].substring(1));
    }else{
         month = parseInt(dateArr[1]);
    }
    var day = parseInt(dateArr[2]);
    var date = new Date(year,month -1,day);
    return date;
}
/**
 * 获取url中的参数
 * @param url
 * @returns {{}}
 */
function getUrl(url) {
    var search = url.search;
    var arr = search.slice(1).split("&");
    var obj = {};
    for (var i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1];
    }
    return obj;
}
/**
 * 验证手机号
 * @param mobile
 * @returns {boolean}
 */
function isMobile(mobile) {
    var reg = /^1[3456789]\d{9}$/;
    if (reg.test(mobile)) {
        return true
    } else {
        return false
    }
}
/**
 * 验证邮箱
 * @param isMailbox
 * @returns {boolean}
 */
function isMailbox(isMailbox) {
    var reg =/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    if (reg.test(isMailbox)) {
        return true
    } else {
        return false
    }
}
/**
 * 验证手机号和电话
 * @param mobileTelphone
 * @returns {boolean}
 */
function isMobileTelphone(mobileTelphone) {
    var regMobilePhone = new RegExp(/^1[345789]\d{9}$/);
    var regTelephone = new RegExp(/^((0\d{2,3})-?)(\d{7,8})(-(\d{3,}))?$/);
    if (regTelephone.test(mobileTelphone)||regMobilePhone.test(mobileTelphone)) {
        return true
    } else {
        return false
    }
}
 /**
  * 弹出框
  */
  function remind(text,tips,state,tips2,callback){
     $("#remindModal").css("z-index", 10000);
     $("#remindModal").find(".C_remindContent div").text(text);
     $("#remindModal").find(".modal-footer .btn").eq(1).text(tips);
     $("#remindModal").find(".modal-footer .btn").eq(0).hide();
     if(state===1){
        $("#remindModal").find(".C_remindContent span").css("color","#ff4222");
        $("#remindModal").find(".modal-footer .btn").eq(0).show();
        $("#remindModal").find(".modal-footer .btn").eq(0).text(tips2);
        if (callback) {
            $("#remindModal").find(".modal-footer .btn").eq(0).bind("click", cb = callback);
        }
     }else if(state===2){
        $("#remindModal").find(".C_remindContent span").css("color","#ff4222");
     }else if(state===undefined){
        $("#remindModal").find(".C_remindContent span").css("color","#4b56a5");
     }
     $("#remindModal").modal({"backdrop":"static"}).modal('show').on("shown.bs.modal",function(){  
      // 是弹出框居中。。。  
      var $modal_dialog = $(this).find('.modal-dialog');  
      //获取可视窗口的高度  
      var clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight: document.documentElement.clientHeight;  
      //得到dialog的高度  
      var dialogHeight = $modal_dialog.height();  
      //计算出距离顶部的高度  
      var m_top = (clientHeight - dialogHeight)/2;  
      $modal_dialog.css({'margin': m_top + 'px auto'});  
  });  
  }
 /**
 * 验证营业执照编码
 * @param LicenseCode
 * @returns {boolean}
 */
function isLicenseCode(LicenseCode){
    var reg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/;
    var reg1=/[\u4e00-\u9fa5]/;
    if (LicenseCode.length == 15 || LicenseCode.length == 18) {
        if (reg.test(LicenseCode) && !reg1.test(LicenseCode)) {
            return true;
        } else {
            return false;
        }
    }else{
        return false;
    }
    
}
/**
 * 上传图片
 * @param {*} f1 
 * @param {*} f2 
 */
function uploadPic(f1,f2) {
    var img_size = f1[0].files[0].size; //用来判断大小
    var reader = new FileReader();//图片预览
    reader.readAsDataURL(f1[0].files[0]);
    reader.onload = function (e) {
        if (img_size >= 1024 * 1024 * 1) { //意思是大于1m 就进行处理
            console.log("图片太大");
           compressImages(e.target.result,f2,f1);//调用自定义方法来处理图片
        }
        else {
            var src = e.target.result;
            f2.attr("src", src);
            f1.attr("value", src);
            
        }
    }
   
}
function compressImages(bdata, imgEle,f1) {//压缩图片
    var quality = 1; //压缩图片的质量
    var oldimglength = bdata.length;//压缩前的大小
    var compresRadio = 0;// 压缩率
    var canvas = document.createElement("canvas"); //创建画布
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = bdata;
    img.onload = function () {
        var width = img.width;
        var height = img.height;
        canvas.width = 500;   //这里可以自定义你的图片大小
        canvas.height = 500 * (img.height / img.width);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        var cdata = canvas.toDataURL("image/png", quality);  //将图片转为Base64 之后预览要用
        imgEle.attr("src", cdata);
        f1.attr("value", cdata);
    }
}
/**
 * 验证身份证号码
 * @param card
 * @returns {boolean}
 */
function isIdCard(card) {
    if(card.length!=18){
        return false
    }
    if (card[card.length - 1] == "x") {
        card = card.substr(0, 17) + "X";
    }
    var reg = /^\d{6}((?:19|20)((?:\d{2}(?:0[13578]|1[02])(?:0[1-9]|[12]\d|3[01]))|(?:\d{2}(?:0[13456789]|1[012])(?:0[1-9]|[12]\d|30))|(?:\d{2}02(?:0[1-9]|1\d|2[0-8]))|(?:(?:0[48]|[2468][048]|[13579][26])0229)))\d{2}(\d)[xX\d]$/
    if (reg.test(card)) {
        var num = card;
        var arr = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        var last = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
        var s = 0;
        for (var i = 0; i < arr.length; i++) {
            s = s + num[i] * arr[i];
        }
        var l = last[s % 11];//最后一位    console.log(l)
        if (l == num[num.length - 1]) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
/**
 * 只能输非中文
 * @param obj
 */
function onlyNotCn(obj) {
    obj.value=obj.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'');
}
/**
 * 日期范围 
 * @param {*} beginSelector 
 * @param {*} endSelector 
 */
function DatePicker(beginSelector,endSelector){  
    // 仅选择日期  
    $(beginSelector).datepicker(  
    {  
      language:  "zh-CN",  
      autoclose: true,  
      startView: 0,  
      format: "yyyy-mm-dd",  
      clearBtn:true,  
      todayBtn:false,  
    }).on('changeDate', function(ev){               
      if(ev.date){  
          $(endSelector).datepicker('setStartDate', new Date(ev.date.valueOf()+24*60*60*1000))  
      }else{  
          $(endSelector).datepicker('setStartDate',null);  
      }  
    })  
    $(endSelector).datepicker(  
    {  
      language:  "zh-CN",  
      autoclose: true,  
      startView:0,  
      format: "yyyy-mm-dd",  
      clearBtn:true,  
      todayBtn:false,  
    }).on('changeDate', function(ev){    
      if(ev.date){  
          $(beginSelector).datepicker('setEndDate', new Date(ev.date.valueOf()-24*60*60*1000))  
      }else{  
          $(beginSelector).datepicker('setEndDate',new Date());  
      }   

    })  
} 